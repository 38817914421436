/*::::::::::Estilos generales de la Aplicación:::::::::::*/
html {
   /* background-color: #56baed;*/
    height: 100%;
  }
  
  @font-face {
    font-family: 'Myriad Pro Regular';
    src: url('../fonts/MYRIADPRO-REGULAR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

  body {
    /*font-family: "Poppins", sans-serif;*/
    font-family: 'Rubik', sans-serif;
    height: 100vh;
   /* background-color: #fff;*/
  }
  
  h1,h2,h3,h4,h5,h6{   
    font-family:'Myriad Pro Regular';
    font-style: italic;
	  color:#002244;
  }
  h1{
    font-weight: 600;
  }
  a {
    color: #92badd;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
  }
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px; 
    color: #002244;
  }

  /*:::::::::::::::::::::Home::::::::::::::::::::::::::::*/
  
   header{
    box-shadow: 0px 0px 10px 0px rgb(96 96 96 / 16%);
   }  
   .home-main{    
     padding: 2rem 0;
   }
  .header_top{
    background-color: #002244;
    padding: 0.6rem 0;
  }
  .header_top_items{
    display: flex;
    justify-content: flex-end;
    color: #fff;
    list-style: none;
    margin: 0;
  }
  .header_top_item{
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:0px 0.5rem;
    padding:0.8rem ;
    color: #006091;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
  }
  
  .header_top_item:hover{
    background-color: #006091;
    color: #fff;
  }
  .header_top_item_icon{
    font-size: 0.8rem;
  }
  .header_down{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 0.5rem;    
  }
  .header_down_icono-linea{
    display: flex;
    align-items: center;  
  }
  .header_down_icono-linea h2{
    font-style: normal;
    font-size: 1.2rem;
  }
  .header_down_icono-linea h2{
    margin: 0px 0px 0px 1rem;
    text-align: left;
  }
  .header_down .btn{
    padding: 0.5rem 1.5rem;
  }
  .btn-text{
    font-weight: bold;
    margin-left: 0.5rem;;
  }
  .btn-sesion{
    background-color: #006091;
  }
  .btn-paga-aqui{
    background-color: #002244;
    margin: 0 2rem ;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
  }
  .btn-paga-aqui:hover{
    background-color: #006091;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    transform: scale(1.1);     
  }
  .btn-paga-aqui:link{    
    background-color: #002244;
  }
  

  .btn-descarga-app{
    background-color: #006091;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;    
  }
  .btn-descarga-app:hover{
    background-color: #006091;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    transform: scale(1.1); 
  }
  .btn-descarga-app::visited,
  .btn-descarga-app::active,
  .btn-descarga-app::link{
    background-color: #002244;
  }
  .btn-sesion:hover{
    background-color: #d42f74;
  }
  .footer{
    background-color: #d42f74;
  }
  .footer-text h6{
    color:#fff;
  }
  /*:::::::::::::::::::Dashboard::::::::::::::::::::::::::*/

  .grid-container{
    display: grid;
    background-color: #56baed;
    grid-template-columns: 15% auto;
  }
  .sidebar-nav{
    width: 270px;
  }
  .menu-barra_lateral_item{
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;   
  }
  .btn-active-menu{
    background-color: #56baed;
    color: #002244 !important;
   
  }
  .menu-barra_lateral_item:hover{
    background-color: #d42f74;
   
  }
  .bg-corporativo{
    background-color: #002244 !important;
  }
  @media (min-width:992px) {

    body{
      overflow: auto !important;
    }
    .offcanvas-backdrop::before{
      display: none;
    }
    main{
      margin-left: 270px;
    }
    .sidebar-nav{
      transform: none;
      visibility: visible !important;
      top:76px;
      height: calc(100% - 76px);
    }
  }
/*=====================================================*/

/*:::::::::::::::::::::::HeaderDasboard::::::::::::::::::::::::::*/

.invisible{
  display: none;
}
/*:::::::::::::::::::::::Crud::::::::::::::::::::::::::*/
input{
  margin:0.7rem 0;
}
.btn-edit{
  background-color: #006091;
  color: #fff;
}
.btn-edit:hover{
  background-color: #002244;
  color:#fff;
}
.btn-crear-usuario{
  background-color: #d42f74;
  color:#fff;
}
.btn-crear-usuario:hover{
background-color: #ac295f;
color:#fff;
}

/*:::::::::::::::::::Menú Lateral::::::::::::::::::::::*/
.menu-lateral{
 background-image: url(../img/bg-login.jpg);
 background-size: cover;
 background-repeat: no-repeat;
}
.logo-blanco{
  width: 80%;
}


/*:::::::::::::::::::Crud Obituarios::::::::::::::::::::::*/

.containerAuxTop{
  display: flex;
  justify-content: space-between;
  
}
.inputBuscar{
  width: 30% !important;
}

.containerInput{
  align-items: end;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 25px;
  width: 50%;
}

@media (max-width: 988px){
  
  .inputBuscar{
    width: 100% !important;
  }

  .App{
    padding: 40px 5px;
  }

  
.containerInput{
  padding-bottom: 15px;
}

}

/*:::::::::::::::::::Paginacion::::::::::::::::::::::*/


.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}
.pagination > .active > a{
  background-color: #47ccde ;
  border-color: #47ccde ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #47ccde ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: #47ccde;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}