.grid-obituarios{
    display: grid;
    /*grid-auto-rows: 25rem;*/
    gap: 1.7rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 18rem), 1fr));
}
.obituarios-titulo{  
    position: relative;
    padding: 1.6rem;
}
.obituarios-titulo::before{ 
    content: '';
    background-image: url(../img/bg-breadrumbs.png);
    background-repeat: repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;    
    opacity: 0.1; 
}
.obituarios-aux-titulo-overlay{
    background-color: rgba(0, 0, 0, 0.02);
}
.logo-obituario{
    width: 25%;
}
.card-obituario{
    box-shadow: 0px 0px 10px 0px rgb(96 96 96 / 16%);   
}
.card-obituario-header{
    background-image: url(../img/bg-login.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #d42f74 ;
    text-align: center;
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 0.5rem;    
}

.card-title{
    color:#002244;
    font-weight: 900;
}
.card-body{
    background-color: #fff;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
}
.card-body:hover{
    background-color:#00224405;
}
.card-obituario_item{
    background-color: rgb(238, 238, 238);
    padding: 0.3rem;
    text-align: center;
    font-weight:bold;   
    border-radius: 3px;   
    font-family:'Myriad Pro Regular';    
	color:#002244;
}
.obituarios-icono{   
    color: #006091
}

select{
    margin: 0.7rem 0;    
}
.destacado{
    font-weight: 700;
}